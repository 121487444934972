<template>
  <div>
    <div class="row">
      <div class="col-12">
        <container-headline
          class="col-xl-12"
          :headline="$t('global.rooms')"
          :col="3"
        >
          <div class="row">
            <div class="ml-4 mr-2">
              <button
                class="btn btn-success"
                @click="newRaum()"
                v-if="berechtigungen.m_ressourcen.create"
              >
                <font-awesome-icon icon="fa-regular fa-plus" />
                {{ $t("global.new") }}
              </button>
            </div>
            <div class="mr-2">
              <button
                v-if="berechtigungen.m_ressourcen.delete"
                class="btn btn-danger mr-2"
                :disabled="selectedIds.length < 1"
                @click="loeschen"
              >
                <font-awesome-icon icon="fa-duotone fa-trash" class="mr-1" />
                {{ $t("global.delete") }}
              </button>
            </div>
          </div>
        </container-headline>
        <div class="row col-xl-12">
          <div class="col-xl-12 block belegliste br-t-l-0">
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                :items="raeume"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                sort-icon-left
                fixed
                show-empty
                selectable
                select-mode="range"
                @row-dblclicked="goToRaum"
                thead-tr-class="pointer"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
                <!--<template v-slot:cell(pflicht)="row">
                    <i
                      class="fa"
                      :class="{
                        'fa-check': row.item.pflicht,
                        'fa-times': !row.item.pflicht,
                      }"
                    />
                  </template>-->
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";

export default {
  name: "RaumlisteReiter",
  components: {
    ContainerHeadline,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    gebaeudeId: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isBusy: false,
      ladeFehler: false,
      sortBy: "",
      sortDesc: false,
      raeume: [],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kurzbezeichnung",
          sortable: true,

          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,

          label: this.$t("global.designation"),
        },
        {
          key: "gebaeude.bezeichnung",
          sortable: true,

          label: this.$t("global.building"),
        },
        {
          key: "geschoss",
          sortable: true,

          label: this.$t("global.floor"),
        },
        {
          key: "sitzplaetze",
          sortable: true,

          label: this.$t("global.seats"),
        },
      ];
    },
  },
  created() {},
  watch: {
    shown(val) {
      if (val && this.raeume.length == 0) this.getVerrechnung();
    },
  },
  mounted() {
    if (this.shown && this.raeume.length == 0) this.getRaueme();
  },
  methods: {
    getRaueme() {
      this.isBusy = true;

      Api.get("/raum/", {
        params: { gebaeude: this.gebaeudeId },
      })
        .then((response) => {
          this.raeume = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    // selectAllRows(row) {
    //   if (row.selected) {
    //     this.$refs.selectableTable.items.forEach((gp) => {
    //       gp.selected = true;
    //       if (!this.selectedIds.includes(gp.id)) {
    //         this.selectedIds.push(gp.id);
    //       }
    //     });
    //   } else {
    //     this.$refs.selectableTable.items.forEach((gp) => {
    //       gp.selected = false;
    //     });
    //     this.selectedIds = [];
    //   }
    //   this.$forceUpdate();
    // },

    // onRowSelected(row) {
    //   if (row.item.selected) {
    //     this.selectedIds.push(row.item.id);
    //   } else {
    //     this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
    //   }
    // },

    loeschen() {
      this.isBusy = true;

      Api.delete("raum/", { params: { ids: this.selectedIds.join(",") } })
        .then(() => {
          this.getRaueme();
          this.selectedIds = [];
          this.ladeFehler = false;
          this.$notify({
            type: "success",
            title: this.$t("global.actionsuccessful"),
            text: this.$t("global.roomdeletedsuccessfully"),
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    newRaum() {
      if (this.gebaeudeId) {
        this.$router.push({
          name: "Neuer Raum",
          params: {
            gebaeudeId: this.gebaeudeId,
          },
        });
      } else {
        this.$router.push({
          name: "Neuer Raum",
        });
      }
    },

    goToRaum(raum) {
      this.$router.push({
        name: "RaumMitId",
        params: {
          id: raum.id,
        },
      });
    },
  },
};
</script>

<style></style>
