var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":_vm.id,"tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Korrekturbuchung")]),_c('modal-close-button',{on:{"confirmed":_vm.closeModal}})],1),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"bezeichnung","options":_vm.belegtypen,"get-option-label":(e) => `${e.bezeichnung}`,"placeholder":" ","clearable":false},model:{value:(_vm.beleg.belegtyp),callback:function ($$v) {_vm.$set(_vm.beleg, "belegtyp", $$v)},expression:"beleg.belegtyp"}}),_c('label',[_vm._v("Belegtyp")])],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"bezeichnung","options":_vm.konten,"get-option-label":(k) =>
                    k && k.bezeichnung && k.kontonummer
                      ? k.kontonummer + ' / ' + k.bezeichnung
                      : '',"placeholder":" ","clearable":false},model:{value:(_vm.beleg.habenkonto),callback:function ($$v) {_vm.$set(_vm.beleg, "habenkonto", $$v)},expression:"beleg.habenkonto"}}),_c('label',[_vm._v("Habenkonto")])],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.beleg.betrag),expression:"beleg.betrag",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"placeholder":" "},domProps:{"value":(_vm.beleg.betrag)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beleg, "betrag", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',[_vm._v("Betrag")])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"label":"bezeichnung","options":_vm.konten,"get-option-label":(k) =>
                    k && k.bezeichnung && k.kontonummer
                      ? k.kontonummer + ' / ' + k.bezeichnung
                      : '',"placeholder":" ","clearable":false},model:{value:(_vm.beleg.sollkonto),callback:function ($$v) {_vm.$set(_vm.beleg, "sollkonto", $$v)},expression:"beleg.sollkonto"}}),_c('label',[_vm._v("Sollkonto")])],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('datepicker',{attrs:{"initial":_vm.beleg.datum,"show-icon":"","date-format":"dd.mm.yy"},on:{"update":(val) => (_vm.beleg.datum = val)}}),_c('label',[_vm._v("Datum")])],1)]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"reduce":(eks) => eks.id,"label":"bezeichnung","options":_vm.kostenstellen,"get-option-label":(k) =>
                    k && k.bezeichnung
                      ? k.kostenstellennummer + ' / ' + k.bezeichnung
                      : '',"placeholder":" ","clearable":false},model:{value:(_vm.beleg.ertragskostenstelle),callback:function ($$v) {_vm.$set(_vm.beleg, "ertragskostenstelle", $$v)},expression:"beleg.ertragskostenstelle"}}),_c('label',[_vm._v("Kostenstelle")])],1)]),_c('div',{staticClass:"col-12 mt-5"},[_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.beleg.buchungstext),expression:"beleg.buchungstext"}],staticClass:"form-control",attrs:{"rows":"4"},domProps:{"value":(_vm.beleg.buchungstext)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.beleg, "buchungstext", $event.target.value)}}}),_vm._v(" "),_c('label',[_vm._v("Buchungstext")])])])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Abbrechen ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.korrekturbuchungErstellen}},[_vm._v(" Korrekturbuchung erstellen ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }