<template>
  <div :id="id" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <modal-close-button @confirmed="closeModal" />
          <h4 class="modal-title">Zertifikate ausgeben</h4>
        </div>
        <div class="modal-body">
          <div class="col-xl-6">
            <div class="form-group">
              <ValidationProvider rules="required" immediate>
                <date-picker
                  date-format="dd.mm.yy"
                  placeholder=" "
                  :show-icon="true"
                  :inModal="true"
                  @update="(val) => setDate(val)"
                />
                <label>Datum</label>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success"
            @click="zertifikateAusgeben"
          >
            Zertifkate ausgeben
          </button>
          <button type="button" class="btn btn-default" @click="closeModal">
            Schliessen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import DatePicker from "@/components/Datepicker";
import ModalCloseButton from "@/components/global/ModalCloseButton";

import { modal } from "@/mixins/Modal";

import store from "@/store";

export default {
  name: "ZertifikatPdfModal",
  mixins: [modal],
  components: {
    DatePicker,
    ModalCloseButton,
  },
  store,
  props: {
    ausgabeZertifikate: {
      type: Function,
      required: false,
    },
    id: {
      type: String,
      required: false,
      default: "zertifikat-pdf-modal",
    },
  },
  data() {
    return {
      selectedDate: new Intl.DateTimeFormat("ch").format(new Date()),
    };
  },
  computed: {},
  mounted() {
    this.$bus.$on("openZertifikatPdfModal", (id) => {
      if (id == this.id && !this.shown) this.openModal();
    });
  },
  methods: {
    zertifikateAusgeben() {
      this.$emit("dateChanged", this.selectedDate);
      this.closeModal();
      this.ausgabeZertifikate();
    },
    setDate(val) {
      this.selectedDate = val;
    },
  },
};
</script>
  
  <style>
</style>
  