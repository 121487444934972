<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.designation"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="1"
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.description"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                        :tabindex="2"
                      />
                      <label>{{ $t("global.description") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="event"
                      :veranstaltung="filters.event"
                      :label="$t('global.event')"
                      :allowOpen="false"
                      editable
                      @confirmed="setFilterVeranstaltung"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <single-select-lehrgang
                      id="lehrgang-select"
                      :label="$t('global.educationcourse')"
                      :lehrgang="filters.course"
                      :allowOpen="false"
                      editable
                      @confirmed="setFilterLehrgang"
                    />
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.surveys')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div
                    class="d-flex align-items-center ml-4"
                    v-if="berechtigungen.m_umfragen.create"
                  >
                    <router-link
                      class="btn btn-success"
                      :to="{ name: 'questionaire', params: { anzeige: 0 } }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                    <button
                      class="btn btn-primary ml-2"
                      @click="copy"
                      :disabled="!hasValueToCopy"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-copy"
                        class="mr-2"
                      />
                      {{ $t("global.Copy") }}
                    </button>
                    <!-- <button class="btn btn-primary ml-2" @click="carbone">
                      <font-awesome-icon
                        icon="fa-duotone fa-copy"
                        class="mr-2"
                      />
                      Carbone
                    </button> -->
                  </div>
                  <div
                    class="mr-4 float-right"
                    v-if="berechtigungen.m_umfragen.delete"
                  >
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_korrespondenz.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="mail-loeschen-modal"
      :selectedIds="this.selectedIds"
      :multiple="true"
      @confirmed="qsLoeschen"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import QuestionaireApi from "@/QuestionaireApi";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import SingleSelectLehrgang from "@/components/global/SingleSelectLehrgang.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/QuestionaireDashboard";

export default {
  name: "QuestionaireListe",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    SingleSelectVeranstaltung,
    SingleSelectLehrgang,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.surveys");
      },
    };
  },
  mixins: [page, dashboard],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return { hasValueToCopy: false };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "designation",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "description",
          sortable: true,
          label: this.$t("global.description"),
        },
        {
          key: "event.kuerzel",
          sortable: true,
          searchable: false,
          label: this.$t("global.event"),
        },
        {
          key: "course.kuerzel",
          sortable: true,
          searchable: false,
          label: this.$t("global.educationcourse"),
        },
      ];
    },
    queryParams: function () {
      var params = {};

      params.designation = this.setQueryParam(this.filters.designation);
      params.description = this.setQueryParam(this.filters.description);
      params.event = this.setQueryParam(this.filters.event?.id);
      params.course = this.setQueryParam(this.filters.course?.id);

      return params;
    },
  },
  watch: {
    selectedIds(val) {
      val != 0 ? (this.hasValueToCopy = true) : (this.hasValueToCopy = false);
    },
  },
  created() {
    this.initFilter("questionaire-liste", "/assessment/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    details(questionaire) {
      this.$router.push({
        name: "questionaire",
        params: { id: questionaire.id, anzeige: 0 },
      });
    },

    carbone() {
      const request = require("request");
      const fs = require("fs");

      const bearerToken =
        "test_eyJhbGciOiJFUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3OTA5NTM4OTc1MTc5OTIyNjQiLCJhdWQiOiJjYXJib25lIiwiZXhwIjoyMzY2MDA0MjUyLCJkYXRhIjp7InR5cGUiOiJ0ZXN0In19.AQJKLh7wwzTjUw1EZlky5D3YMmTGdQRglEPmB1XEKtwEaj1susucccZMOoWSkOp8rfAEN6p1BvbjVy7YFLezufsxAGPNBMmSAgZ9PeK2JycacUYeriBMJqo8vPwczzBMSI2EsO8z4RXeMQYqsPBNWg366LRN2dCinDLhbJTxczKlYO_1";
      const templateId =
        "46cae4ebbf7592deaecab432358c300fdf9b3952188f3b5b15bdf34a0d47667a";

      request.post(
        {
          url: "https://api.carbone.io/render/" + templateId,
          json: true,
          body: {
            data: {
              lastname: "Ochmann",
              firstname: "Phil",
              date: "03.01.2024",
              email: "phil.ochmann@batix.ch",
              adress: {
                street: "Teststrasse",
                number: "27",
                city: "Luzern",
                zip: "1111",
              },
            },
            convertTo: "pdf",
          },
          headers: {
            Authorization: "Bearer " + bearerToken,
            "carbone-version": "4",
          },
        },
        (err, response, body) => {
          // Handle error
          // Body contains an error or the render ID

          let bufferArray = [];
          let renderID = body.data.renderId;

          var xhr = new XMLHttpRequest();
          xhr.open("GET", "https://api.carbone.io/render/" + renderID, true);
          xhr.responseType = "arraybuffer";
          xhr.onload = function (e) {
            if (this.status == 200) {
              var blob = new Blob([this.response], { type: "application/pdf" });
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "Report_" + new Date() + ".pdf";
              link.click();
            }
          };
          xhr.send();
        }
      );
    },

    copy() {
      QuestionaireApi.get("assessment/", {
        params: { idsToCopy: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },
    qsLoeschen() {
      QuestionaireApi.delete("assessment/" + this.selectedIds[0], {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "mail-loeschen-modal");
    },
    setFilterVeranstaltung(veranstaltung) {
      this.$set(this.filters, "event", veranstaltung);
    },
    setFilterLehrgang(lehrgang) {
      this.$set(this.filters, "course", lehrgang);
    },
  },
};
</script>

<style></style>
