<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.name"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.company") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.plz"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.zipcode") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.ort"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.city") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['kanton']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.canton") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.interesse"
                        :reduce="(i) => i.id"
                        :options="interessen"
                        label="bezeichnung"
                        :placeholder="$t('global.interests')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nointerestfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.interests") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.konkordat"
                        :reduce="(i) => i.id"
                        :options="konkordate"
                        label="bezeichnung"
                        :placeholder="$t('global.concordat')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nointerestfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.concordat") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.versand"
                        :reduce="(vl) => vl.id"
                        :options="versandlisten"
                        label="bezeichnung"
                        :placeholder="$t('global.dispatch')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nomailinglistfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.mailinglist") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'" class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.firmarolle"
                        :reduce="(fr) => fr.id"
                        :options="firmarollen"
                        label="bezeichnung"
                        :placeholder="$t('global.type')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.notypefound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.type") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12 my-2">
                    <div class="form-group-inline" style="margin-left: 7px">
                      <label
                        class="float-left"
                        style="
                          font-size: 11px !important;
                          font-weight: 700 !important;
                        "
                        >{{ $t("global.notsubsidized") }}</label
                      ><input
                        class="form-check-input float-right mx-3"
                        style="margin-top: 0.2rem"
                        v-model="filters['nicht_subventioniert']"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
                <!--Oder Statement switch-->
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <button
                        class="btn btn-secondary"
                        @click="toggleOrParameter"
                      >
                        {{
                          filters.orStatement
                            ? $t("global.orfilteractive")
                            : $t("global.orfilterinactive")
                        }}
                      </button>
                    </div>
                  </div>
                </div>
                <!--Ende Oder Statement switch-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.companylist')"
              :date="'Mittwoch 29.Juli'"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>

            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4">
                    <router-link
                      v-if="
                        !rolleProp && berechtigungen.m_geschaeftspartner.create
                      "
                      class="btn btn-success"
                      :to="{
                        name: 'firma',
                        params: { anzeige: 0 },
                      }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>

                    <span v-if="$CONST('CONFIG').APPTYPE === 'Education'">
                      <b-dropdown variant="primary ml-2">
                        <template slot="button-content">
                          <font-awesome-icon
                            icon="fa-duotone fa-print"
                            class="mr-2"
                          />
                          {{ $t("global.labels") }}
                        </template>
                        <b-dropdown-item @click="ausgebenAvery365221">
                          HERMA_4451_21
                        </b-dropdown-item>
                        <b-dropdown-item @click="ausgebenAvery365314">
                          HERMA_4452_14
                        </b-dropdown-item>
                        <!--b-dropdown-item @click="ausgebenZweckform478427">
                          Zweckform 4784 27
                        </b-dropdown-item-->
                      </b-dropdown>
                    </span>

                    <b-dropdown
                      variant="primary ml-2"
                      v-if="berechtigungen.m_korrespondenz.update"
                    >
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-regular fa-envelope"
                          class="mr-2"
                        />
                        {{ $t("global.createmassmail") }}
                      </template>
                      <b-dropdown-item @click="massenmailErstellen(true)">
                        {{ $t("global.selected") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="massenmailErstellen(false)">
                        {{ $t("global.alldisplayed") }}
                      </b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown
                      variant="primary ml-2"
                      v-if="berechtigungen.m_korrespondenz.update"
                    >
                      <template slot="button-content">
                        <font-awesome-icon
                          icon="fa-regular fa-envelope"
                          class="mr-2"
                        />
                        {{ $t("global.createserialletter") }}
                      </template>
                      <b-dropdown-item
                        @click="massenkorrespondenzErstellen(true)"
                      >
                        {{ $t("global.selected") }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="massenkorrespondenzErstellen(false)"
                      >
                        {{ $t("global.alldisplayed") }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <button
                      class="btn btn-primary ml-2"
                      v-if="berechtigungen.m_geschaeftspartner.create"
                      v-tooltip.hover
                      title="Bitte wählen Sie maximal 5 Einträge aus der Liste aus"
                      :disabled="
                        this.selectedIds.length < 2 ||
                        this.selectedIds.length > 5
                      "
                      @click="oeffneFirmenDublettenZusammenfuehrenModal"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-arrows-minimize"
                        class="mr-2"
                      />{{ $t("global.mergeselecteditems") }}
                    </button>
                  </div>
                  <div class="mr-4 float-right">
                    <button
                      class="btn btn-danger"
                      v-if="berechtigungen.m_geschaeftspartner.delete"
                      @click="oeffneLoeschenModal"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    no-local-sorting
                    @sort-changed="sortingChanged"
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                    thead-tr-class="pointer"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <b-input
                          v-if="
                            field.key != 'selected' &&
                            field.searchable !== false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                          autocomplete="off"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(name)="row">
                      <div v-if="row.item.abteilungen.length > 0">
                        {{ row.item.name }}
                        <font-awesome-icon
                          v-tooltip.hover="
                            (text =
                              'Abteilungen: <br>' +
                              (row.item.abteilungen
                                ? row.item.abteilungen
                                    .map((abt) => abt.bezeichnung)
                                    .join('<br>')
                                : ''))
                          "
                          icon="fa-solid  fa-circle-info"
                          class="pl-2 pr-2 pt-1"
                        />
                      </div>
                      <div v-else class="item">
                        {{ row.item.name }}
                      </div>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">
                      {{ $t("global.loadmorecompanies") }}
                    </div>
                    <div slot="no-more">
                      {{ $t("comapnies.nofurthercompanies") }}
                    </div>
                    <div slot="no-results">
                      {{ $t("global.nofurthercompaniesfound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="firma-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
      @confirmed="firmaLoeschen"
    />

    <pdf-layer
      id="pdf-layer"
      ref="pdfLayer"
      :src="{
        url: pdfPath,
        withCredentials: true,
      }"
    ></pdf-layer>

    <loading-overlay v-show="loading"></loading-overlay>

    <firmen-dubletten-zusammenfuehren-modal
      id="firmen-dubletten-zusammenfuehren-modal"
      :selectedIds="selectedIds"
      @zusammenfuehrenAbgeschlossen="listeNeuLaden"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import server from "@/server";

import store from "@/store";
import Api from "@/Api";

import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";
import { LADE_VERSANDLISTEN } from "@/store/korrespondenz/actions.type";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import FirmenDublettenZusammenfuehrenModal from "@/components/crm/FirmenDublettenZusammenfuehrenModal.vue";

export default {
  name: "Firmenliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
    FilterSearchResetButton,
    FirmenDublettenZusammenfuehrenModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.company");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {
    rolleProp: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      sortBy: "firma",
      sortDesc: false,
      pdfPath: "",

      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "firmennummer",
          sortable: true,
          label: "Nr",
          tdClass: "col-number",
        },
        { key: "name", sortable: true, label: this.$t("global.company") },
        {
          key: "mailadressen[0].bezeichnung",
          sortable: true,
          label: this.$t("global.email"),
        },
        {
          key: "adresszusatz",
          sortable: true,
          label: this.$t("global.addresssuffix"),
          formatter: (value, key, item) => {
            if (item && item.adressen && item.adressen.length > 0)
              return item.adressen[0].adresszusatz;
            else return "";
          },
        },
        {
          key: "strasse_nr",
          sortable: true,
          label: this.$t("global.street"),
          formatter: (value, key, item) => {
            if (item && item.adressen && item.adressen.length > 0)
              return item.adressen[0].strasse_nr;
            else return "";
          },
        },
        {
          key: "plz",
          sortable: true,
          label: this.$t("global.zipcode"),
          formatter: (value, key, item) => {
            if (item && item.adressen && item.adressen.length > 0)
              return item.adressen[0].plz;
            else return "";
          },
        },
        {
          key: "ort",
          sortable: true,
          label: this.$t("global.city"),
          formatter: (value, key, item) => {
            if (item && item.adressen && item.adressen.length > 0)
              return item.adressen[0].ort;
            else return "";
          },
        },
        {
          key: "kanton",
          sortable: true,
          searchable: true,
          class: "text-center",
          label: this.$t("global.canton"),
          formatter: (value, key, item) => {
            if (item && item.adressen && item.adressen.length > 0)
              return item.adressen[0].kanton;
            else return "";
          },
        },
        {
          key: "land",
          sortable: true,
          label: this.$t("global.country"),
          formatter: (value, key, item) => {
            if (item && item.adressen && item.adressen.length > 0)
              return item.adressen[0].land?.name;
            else return "";
          },
        },
      ];
    },
    queryParams: function () {
      var params = {};

      if (this.sortField) {
        params.sdir = this.sortDir;
        params.sfield = this.sortField;
      }

      params.firmenname = this.setQueryParam(this.filters.firmenname);
      params.zip = this.setQueryParam(this.filters.plz);
      params.ort = this.setQueryParam(this.filters.ort);
      params.kanton = this.setQueryParam(this.filters["kanton"]);
      params.firma = this.setQueryParam(this.filters.name);
      params.adresszusatz = this.setQueryParam(this.filters.adresszusatz);
      params.strasse_nr = this.setQueryParam(this.filters.strasse_nr);
      params.land = this.setQueryParam(this.filters.land);
      params.typ = "17EF88469AE";

      params.ip = true;
      params.aec = true;

      params.versand = this.setQueryParamList(this.filters.versand);
      params.interesse = this.setQueryParamList(this.filters.interesse);
      params.firmarolle = this.setQueryParamList(this.filters.firmarolle);

      params.konkordat = this.setQueryParamList(this.filters.konkordat);
      params.orStatement = this.setQueryParam(
        this.filters.orStatement ? "true" : undefined
      );

      params.filterCount = this.setQueryParam(this.filters.count);
      params.mailadresse = this.setQueryParam(
        this.filters["mailadressen[0].bezeichnung"]
      );

      params.nicht_subventioniert = this.setQueryParam(
        this.filters["nicht_subventioniert"]
      );
      params.firmennummer = this.setQueryParam(this.filters.firmennummer);

      return params;
    },
    interessen: {
      get() {
        return this.$store.state.geschaeftspartner.interessen;
      },
    },
    firmarollen: {
      get() {
        return this.$store.state.geschaeftspartner.firma_partnerrollen;
      },
    },
    versandlisten: {
      get() {
        return this.$store.state.korrespondenz.versandlisten;
      },
    },
    konkordate: {
      get() {
        return this.$store.state.geschaeftspartner.konkordate;
      },
    },
  },
  created() {
    if (this.interessen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    if (this.versandlisten.length == 0)
      this.$store.dispatch(`korrespondenz/${LADE_VERSANDLISTEN}`);

    this.initFilter("firmen-liste", "geschaeftspartner/firma/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
  },
  methods: {
    details(person) {
      this.$router.push({
        name: "firma",
        params: { id: person.id, anzeige: 0 },
      });
    },

    sortingChanged(ctx) {
      this.sortDir = ctx.sortDesc;
      this.sortField = ctx.sortBy;
      this.resetLoading(this);
    },

    toggleOrParameter() {
      if (this.filters.orStatement) {
        this.$set(this.filters, "orStatement", false);
      } else {
        this.$set(this.filters, "orStatement", true);
      }
      this.filterSearch();
    },

    ausgebenAvery365221() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/firma-avery-3652-21.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },

    ausgebenAvery365314() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/firma-avery-3653-14.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },

    /*ausgebenZweckform478427() {
      let ids = this.selectedIds.join(",");
      let filename = new Intl.DateTimeFormat("ch").format(new Date());

      this.pdfPath =
        server.url +
        `/pdfgenerator/pdf.act?filename=AV${filename}.pdf&report=/pdfgenerator/firma-zweckform-4784-27.htm&ids=${ids}`;
      this.$refs.pdfLayer.show();
    },*/

    massenmailErstellen(selected) {
      if (!selected || this.selectedIds.length > 0) {
        let tempList = selected
          ? this.selectedIds
          : this.$refs.selectableTable.items.map((item) => item.id);

        let json = {
          firma: tempList,
          isMassMail: true,
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        Api.post("mail/", json, { params: {} }).then((response) => {
          this.$router.push({
            name: "mail",
            params: {
              id: response.data.id,
              anzeige: 0,
            },
          });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonsselected"),
        });
    },

    massenkorrespondenzErstellen(selected) {
      if (!selected || this.selectedIds.length > 0) {
        let tempList = selected
          ? this.selectedIds
          : this.$refs.selectableTable.items.map((item) => item.id);

        let json = {
          empfaenger: [],
          firma: [], // ist de facto person. Noch unsauber im Backend
          isMassKorrespondenz: true,
          versand: {
            erstelltvon: this.angemeldeterMitarbeiter.person,
            datum: new Intl.DateTimeFormat("ch").format(new Date()),
            ort: this.$CONST("CONFIG").MANDANTSTADT,
            betreff: "",
          },
          inhalt: "",
          zeitpunkt: new Intl.DateTimeFormat("ch").format(new Date()),
        };

        json.empfaenger = tempList;
        json.firma = tempList;

        Api.post("korrespondenz/", json, { params: {} }).then((response) => {
          Api.post("korrespondenz/empfaenger/", json, {
            params: {
              korrespondenz: response.data.id,
              isFirma: true,
              isMassCorr: true,
            },
          }).then((empfresponse) => {}),
            this.$router.push({
              name: "korrespondenz",
              params: {
                id: response.data.id,
                anzeige: 0,
              },
            });
        });
      } else
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.nopersonselected"),
        });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0) console.log(this.selectedIds);
      this.$bus.$emit("openLoeschenModal", "firma-loeschen-modal");
    },

    firmaLoeschen() {
      Api.delete("geschaeftspartner/firma/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },
    oeffneFirmenDublettenZusammenfuehrenModal() {
      this.$bus.$emit("open-modal", "firmen-dubletten-zusammenfuehren-modal");
    },
    listeNeuLaden() {
      this.resetLoading(this);
    },
  },
};
</script>

<style></style>
