<template>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    @click="close"
    v-tooltip.hover
    :title="$t('global.closemodal')"
  >
    <span aria-hidden="true">
      <font-awesome-icon icon="fa-light fa-times" :style="{ color: 'white' }" />
    </span>
  </button>
</template>

<script>
export default {
  name: "ModalCloseButton",
  methods: {
    close() {
      /* Durch das Data-Attribut 'data-dismiss="modal"' werden alle offenen Modale auf der Seite geschlossen */
      /* ModalCloseButton und ModalCloseButtonNew werden für den nächsten Release refactored */
      this.$emit("confirmed");
    },
  },
};
</script>

<style>
</style>
