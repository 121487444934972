<template>
  <div class="list">
    <navbar :docsName="docsName" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <!-- Start FILTER -->
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.filterCount"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.bezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.kurzbezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['gebaeude.bezeichnung']"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.building") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['geschoss']"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.floor") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['sitzplaetze']"
                        class="form-control input"
                        type="text"
                        placeholder=""
                      />
                      <label>{{ $t("global.seats") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />

                <!--
                <div class="row pt-4 pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model="filterBezeichnung"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>Bezeichnung</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <input
                          v-model.trim="filterKurzbezeichnung"
                          class="form-control input"
                          type="text"
                          placeholder=" "
                        />
                        <label>Kurzbezeichnung</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pb-3">
                  <div class="col-12">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <v-select label="bezeichnung" placeholder="Status">
                          <span slot="no-options">Kein Status gefunden</span>
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
                -->
              </div>
            </div>

            <!-- Ende FILTER -->
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.room')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
              :gesamtanzahl="gesamtanzahl"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row justify-content-between">
                  <div class="ml-4 mr-2">
                    <router-link
                      v-if="berechtigungen.m_ressourcen.create"
                      class="btn btn-success"
                      :to="{ name: 'Neuer Raum' }"
                    >
                      <font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}
                    </router-link>
                  </div>

                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_ressourcen.delete"
                      :disabled="!editable || selectedIds.length === 0"
                    >
                      <font-awesome-icon
                        class="mr-2"
                        icon="fa-duotone fa-trash"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td
                        class="no-pointer"
                        v-for="field in fields"
                        :key="field.key"
                      >
                        <div class="form-group p-0 m-0">
                          <input
                            class="form-control"
                            v-if="
                              field.key != 'selected' &&
                              field.searchable != false
                            "
                            size="sm"
                            type="text"
                            v-model="filters[field.key]"
                            :placeholder="field.label"
                            autocomplete="off"
                          />
                        </div>
                      </td>
                    </template>

                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <!-- Deska Ansicht -->
                  <b-table
                    v-if="$CONST('CONFIG').APPTYPE === 'DESKA'"
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fieldsDeska"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
            <div id="modal-loeschen" class="modal fade">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4>
                      <font-awesome-icon
                        icon="fa-regular fa-warning"
                        class="mr-2 text-warning"
                      />{{ $t("global.deleteroom") }}
                    </h4>
                  </div>
                  <div class="modal-body">
                    {{ $t("global.doyouwantdeletetherooms") }}
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn btn-default"
                      @click="schliesseLoeschenModal"
                    >
                      {{ $t("global.cancel") }}
                    </button>
                    <button class="btn btn-primary" @click="loeschen">
                      {{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import DatePicker from "@/components/Datepicker";
import Api from "@/Api";
//import { debounce } from "@/utils/Debouncer";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import filter from "@/mixins/Filter";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import InfiniteLoading from "vue-infinite-loading";

import { insertUrlParam, removeUrlParam } from "@/utils/Url";
import { LADE_AUSWAHLWERTE_RESSOURCEN } from "@/store/ressourcen/actions.type";

export default {
  name: "Raumliste",
  components: {
    Navbar,
    InfiniteLoading,
    HeadMenu,
    HeadMenuFilter,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return this.$t("global.room");
      },
    };
  },
  mixins: [page, filter],
  props: {
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  store,
  data() {
    return {
      //page: 0,
      //raumliste: [],
      //filterCount: null,
      anzahlDaten: null,
      anzahlDatenFilter: null,

      //anzahlProPage: 50,
      //infiniteId: +new Date(),
      sortBy: "bezeichnung",
      sortDesc: false,
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    fields() {
      return [
        { key: "selected", label: "" },
        {
          key: "kurzbezeichnung",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "gebaeude.bezeichnung",
          sortable: true,
          label: this.$t("global.building"),
        },
        {
          key: "geschoss",
          sortable: true,
          label: this.$t("global.floor"),
        },
        {
          key: "sitzplaetze",
          sortable: true,
          label: this.$t("global.seats"),
        },
      ];
    },
    fieldsDeska() {
      return [
        { key: "selected", label: "" },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "buchbar",
          sortable: true,
          label: this.$t("global.bookable"),
          formatter: (value) => {
            return value ? "X" : "";
          },
        },
        {
          key: "raumbeschriftung",
          sortable: true,
          label: this.$t("global.roomlabel"),
        },
        {
          key: "gebaeude.bezeichnung",
          sortable: true,
          label: this.$t("global.building"),
        },
        { key: "geschoss", sortable: true, label: this.$t("global.floor") },
        { key: "sitzplaetze", sortable: true, label: this.$t("global.seats") },
      ];
    },
    queryParams: function () {
      var params = {};

      /*if (this.filterBezeichnung)
        params.filterBezeichnung = this.filterBezeichnung;
      if (this.filterKurzbezeichnung)
        params.filterKurzbezeichnung = this.filterKurzbezeichnung;*/
      if (this.filters.filterCount != "")
        params.filterCount = this.setQueryParam(this.filters.filterCount);

      params.designation = this.setQueryParam(this.filters.bezeichnung);
      params.shortdesignation = this.setQueryParam(
        this.filters.kurzbezeichnung
      );
      params.building = this.setQueryParam(
        this.filters["gebaeude.bezeichnung"]
      );
      params.floor = this.setQueryParam(this.filters.geschoss);
      params.seats = this.setQueryParam(this.filters.sitzplaetze);

      return params;
    },

    headline() {
      return "BX: Raum";
    },
  },
  watch: {
    filter: function (val) {
      if (this.showNavbar)
        val ? insertUrlParam("filter", val) : removeUrlParam("filter");
      this.resetLoading(this);
    },
    /* filterCount: function () {
      this.resetLoading(this);
    }, */
  },
  created() {
    this.initFilter("raumliste", "raum/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.editable = this.berechtigungen.m_ressourcen.delete;
  },
  methods: {
    details(raumliste) {
      this.$router.push({
        path: "/ressourcen/raum/" + raumliste.id,
      });
    },
    /* resetLoading: debounce((self) => {
      self.page = 0;
      self.raumliste = [];
      self.infiniteId += 1;
    }, 500), */
    loeschen() {
      Api.delete("raum/", {
        params: { ids: this.selectedIds.join(",") },
      })
        .then((response) => {
          this.schliesseLoeschenModal();

          this.listData = this.listData.filter(
            (v) => !response.data.success.includes(v.id)
          );
          if (response.data.success.length !== 0) {
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.roomdeletedsuccessfully"),
            });
            this.$store.dispatch(`ressourcen/${LADE_AUSWAHLWERTE_RESSOURCEN}`);
          } else {
            this.$notify({
              type: "warn",
              title: this.$t("global.actionfailed"),
              text: this.$t("global.roomdeletionunsuccessful"),
            });
          }
        })
        .finally(() => {
          this.selectedIds = [];
        });
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    /* infiniteHandler($state) {
      Api.get("raum/", {
        params: this.queryParams,
      }).then((response) => {
        //this.anzahlDaten = response.data[0].count;
        //response.data.shift();
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.raumliste.push(...response.data);
          this.anzahlDatenFilter = this.raumliste.length;
          $state.loaded();
        } else {
          this.raumliste.push(...response.data);
          this.anzahlDatenFilter = this.raumliste.length;
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    }, */
  },
};
</script>

<style></style>
