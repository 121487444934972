<template>
  <div>
    <!-- TITEL SEKTIONSGRUPPE -->

    <div class="ml-1">
      <div class="row mx-0">
        <div class="block block-kopf col-7 d-flex align-items-center">
          <label class="col p-0 mb-0 d-flex align-items-center">
            <font-awesome-icon
              class="clickable mr-2"
              icon="fa-regular fa-pencil"
            />
            <input
              class="col-11 primary-headline-text p-0 float-lef"
              type="text"
              :placeholder="$t('global.ownsectiongroup')"
              v-model="sektionsgruppe.bezeichnung"
              maxlength="100"
              @keydown.enter="$event.target.blur()"
              style="
                color: #124862;
                background-color: #b5cfdc;
                border: none;
                outline: none;
                height: 100% !important;
              "
              v-tooltip.hover
              :title="
                sektionsgruppe.bezeichnung ? sektionsgruppe.bezeichnung : null
              "
            />
          </label>
        </div>
      </div>

      <!-- SEKTIONSGRUPPE INKL. BUTTONS -->

      <div class="row mx-0 mb-3">
        <div class="col-xl-12 bg-medium-blue p-2 br-t-r-10">
          <b-collapse
            visible
            v-bind:id="'collapse-' + 'sektionsgruppe-' + sektionsgruppenIndex"
          >
            <div class="row mx-0">
              <div
                v-for="(sektion, index) in sektionsgruppe.sektionen"
                :key="index"
                class="col-12 px-2 pb-2 pt-0 mb-5 bg-accent-blue rounded-lg pos-r"
              >
                <!-- SEKTIONSTITEL -->

                <div class="row mb-1 d-flex align-items-center">
                  <div class="col-12 d-flex mt-3 mb-2">
                    <label class="col-12 d-flex align-items-center">
                      <font-awesome-icon
                        icon="fa-regular fa-pencil"
                        class="ml-1 mr-2 fs-14 clickable"
                      />

                      <input
                        class="w-100-p bg-accent-blue border-none outline-none text-primary"
                        type="text"
                        :placeholder="$t('global.section')"
                        v-model="sektion.bezeichnung"
                        @keydown.enter="$event.target.blur()"
                        maxlength="100"
                        v-tooltip.hover
                        :title="
                          sektion.bezeichnung ? sektion.bezeichnung : null
                        "
                      />
                    </label>
                  </div>
                </div>

                <b-collapse
                  visible
                  v-bind:id="
                    'collapse-' +
                    'sektionsgruppe-' +
                    sektionsgruppenIndex +
                    '-sektion-' +
                    index
                  "
                >
                  <!-- SEKTION SEMESTER -->
                  <div class="row mx-0">
                    <!--
                  <div class="col-2 order-2 p-0 pl-1 d-flex flex-wrap">
                    <div
                      class="eingabe-panel-kopf p-3 mb-1 d-flex align-items-center"
                    >
                      <input
                        v-model.number="sektion.gewichtung"
                        class="form-control"
                        :placeholder="$t('global.weighting')"
                      />
                    </div>
                  </div>-->
                    <div class="col-12 mx-0 p-0">
                      <div
                        v-for="(
                          sektionsfeld, sektionsfeldIndex
                        ) in sektion.sektionsfelder"
                        :key="sektionsfeldIndex"
                      >
                        <div
                          class="col-12 eingabe-panel-kopf p-3 mb-1 float-left"
                        >
                          <div class="row">
                            <label class="col-12 d-flex align-items-center">
                              <font-awesome-icon
                                icon="fa-regular fa-pencil"
                                class="ml-1 mr-2 fs-14 clickable"
                              />
                              <input
                                class="w-100-p bg-transparent border-none outline-none text-primary"
                                type="text"
                                :placeholder="$t('global.sectionfield')"
                                v-model="sektionsfeld.bezeichnung"
                                @keydown.enter="$event.target.blur()"
                                maxlength="100"
                                v-tooltip.hover
                                :title="
                                  sektionsfeld.bezeichnung
                                    ? sektionsfeld.bezeichnung
                                    : null
                                "
                              />
                            </label>
                          </div>
                          <div class="row mx-0">
                            <div
                              class="col-12 d-flex flex-wrap p-3 mb-3"
                              style="min-height: 60px"
                              :style="styleDropzone"
                              @drag="setDropzoneColor()"
                              @drop="onDrop(index, sektionsfeldIndex)"
                              @dragend="setDefaultDropzoneColor()"
                              @dragenter.prevent
                              @dragover.prevent
                            >
                              <div v-if="endnotenReiter == false">
                                <div
                                  v-for="(
                                    fach, fachIndex
                                  ) in sektionsfeld.faecher"
                                  :key="fachIndex"
                                  :style="[
                                    styleDragElement,
                                    {
                                      backgroundColor: fach.color
                                        ? fach.color
                                        : '#deedf6',
                                    },
                                  ]"
                                  class="m-1 px-2 h-42-px eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                  draggable
                                  filter=".action-button"
                                  @dragstart="startDrag($event, fach, 'fach')"
                                >
                                  <span>
                                    {{ fach.bezeichnungKuerzel }}
                                  </span>
                                  <div
                                    class="clickable d-flex align-items-center float-right py-3 px-2 ml-3 h-100-p border-left border-primary"
                                    @click="
                                      deleteFach(
                                        index,
                                        sektionsfeldIndex,
                                        fachIndex,
                                        'fach'
                                      )
                                    "
                                  >
                                    <font-awesome-icon
                                      icon="fa-light fa-times"
                                      size="sm"
                                      class="ml-1"
                                    />
                                  </div>
                                </div>

                                <div
                                  v-for="(
                                    noteneingabe, noteneingabeIndex
                                  ) in sektionsfeld.noteneingaben"
                                  :key="'Noteneingabe' + noteneingabeIndex"
                                  :style="[
                                    styleDragElement,
                                    {
                                      backgroundColor: noteneingabe.color
                                        ? noteneingabe.color
                                        : '#deedf6',
                                    },
                                  ]"
                                  class="m-1 px-2 h-42-px eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                  draggable
                                  filter=".action-button"
                                  @dragstart="
                                    startDrag(
                                      $event,
                                      noteneingabe,
                                      'noteneingabe'
                                    )
                                  "
                                >
                                  <span>
                                    {{ noteneingabe.bezeichnung }}
                                  </span>
                                  <div
                                    class="clickable d-flex align-items-center float-right py-3 px-2 ml-3 h-100-p border-left border-primary"
                                    @click="
                                      deleteFach(
                                        index,
                                        sektionsfeldIndex,
                                        noteneingabeIndex,
                                        'noteneingabe'
                                      )
                                    "
                                  >
                                    <font-awesome-icon
                                      icon="fa-light fa-times"
                                      size="sm"
                                      class="ml-1"
                                    />
                                  </div>
                                </div>
                              </div>

                              <!-- SEKTION ENDNOTE -->

                              <div v-if="endnotenReiter == true">
                                <div
                                  v-for="(
                                    fach, endnotenfachIndex
                                  ) in sektionsfeld.faecher"
                                  :key="'endnotenfach ' + endnotenfachIndex"
                                  :style="[
                                    styleDragElement,
                                    {
                                      backgroundColor: fach.color
                                        ? fach.color
                                        : '#deedf6',
                                    },
                                  ]"
                                  class="m-1 px-2 h-42-px eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                  draggable
                                  filter=".action-button"
                                  @dragstart="startDrag($event, fach, 'fach')"
                                >
                                  <span>
                                    {{ fach.bezeichnung }}
                                  </span>
                                  <div
                                    class="clickable d-flex align-items-center float-right py-3 px-2 ml-3 h-100-p border-left border-primary"
                                    @click="
                                      deleteFach(
                                        index,
                                        sektionsfeldIndex,
                                        endnotenfachIndex,
                                        'fach'
                                      )
                                    "
                                  >
                                    <font-awesome-icon
                                      icon="fa-light fa-times"
                                      size="sm"
                                      class="ml-1"
                                    />
                                  </div>
                                </div>

                                <div
                                  v-for="(
                                    endnotensektionsgruppe,
                                    endnotensektionsgruppeIndex
                                  ) in sektionsfeld.endnotensektionsgruppen"
                                  :key="endnotensektionsgruppeIndex"
                                  :style="[
                                    styleDragElement,
                                    {
                                      backgroundColor:
                                        endnotensektionsgruppe.color
                                          ? endnotensektionsgruppe.color
                                          : '#deedf6',
                                    },
                                  ]"
                                  class="m-1 px-2 h-42-px eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                  draggable
                                  filter=".action-button"
                                  @dragstart="
                                    startDrag(
                                      $event,
                                      endnotensektionsgruppe,
                                      'sektionsgruppe'
                                    )
                                  "
                                >
                                  <span>
                                    {{
                                      endnotensektionsgruppe.bezeichnung
                                        ? endnotensektionsgruppe.bezeichnung
                                        : "Sektionsgruppe"
                                    }}
                                  </span>
                                  <div
                                    class="clickable d-flex align-items-center float-right py-3 px-2 ml-3 h-100-p border-left border-primary"
                                    @click="
                                      deleteFach(
                                        index,
                                        sektionsfeldIndex,
                                        endnotensektionsgruppeIndex,
                                        'sektionsgruppe'
                                      )
                                    "
                                  >
                                    <font-awesome-icon
                                      icon="fa-light fa-times"
                                      size="sm"
                                      class="ml-1"
                                    />
                                  </div>
                                </div>

                                <div
                                  v-for="(
                                    endnoteneingabe, endnoteneingabeIndex
                                  ) in sektionsfeld.endnotenNoteneingaben"
                                  :key="
                                    'Endnoteneingabe' + endnoteneingabeIndex
                                  "
                                  :style="[
                                    styleDragElement,
                                    {
                                      backgroundColor: endnoteneingabe.color
                                        ? endnoteneingabe.color
                                        : '#deedf6',
                                    },
                                  ]"
                                  class="m-1 px-2 h-42-px eingabe-panel-kopf d-inline-flex align-items-center text-primary"
                                  draggable
                                  filter=".action-button"
                                  @dragstart="
                                    startDrag(
                                      $event,
                                      endnoteneingabe,
                                      'noteneingabe'
                                    )
                                  "
                                >
                                  <span>
                                    {{ endnoteneingabe.bezeichnung }}
                                  </span>
                                  <div
                                    class="clickable d-flex align-items-center float-right py-3 px-2 ml-3 h-100-p border-left border-primary"
                                    @click="
                                      deleteFach(
                                        index,
                                        sektionsfeldIndex,
                                        endnoteneingabeIndex,
                                        'noteneingabe'
                                      )
                                    "
                                  >
                                    <font-awesome-icon
                                      icon="fa-light fa-times"
                                      size="sm"
                                      class="ml-1"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- SEKTIONSFELD DROPDOWN / BUTTONS / GEWICHTUNG -->
                            <div class="col-12 p-0 d-flex align-items-center">
                              <div class="d-flex flex-column mr-auto">
                                <label class="mb-0 mr-2 text-bold fs-10">{{
                                  $t("global.weighting") +
                                  " " +
                                  $t("global.sectionfield")
                                }}</label>
                                <input
                                  v-model.number="sektionsfeld.gewichtung"
                                  class="form-control h-25-px w-50-px px-1 text-center mr-2"
                                  placeholder="1"
                                />
                              </div>

                              <div class="d-flex flex-column mr-auto">
                                <label class="mb-0 mr-2 text-bold fs-10">{{
                                  $t("global.scoretype")
                                }}</label>
                                <select
                                  class="clickable text-primary bg-light-blue border-0 overflow-hidden text-truncate h-80-p fs-12"
                                  name="notentyp"
                                  v-model="sektionsfeld.notentyp"
                                  label="bezeichnung"
                                  v-tooltip.hover
                                  :title="
                                    sektionsfeld.notentyp
                                      ? sektionsfeld.notentyp.bezeichnung
                                      : null
                                  "
                                >
                                  <option
                                    :value="null"
                                    disabled
                                    selected
                                    hidden
                                  >
                                    {{ $t("global.scoretype") }}
                                  </option>
                                  <option :value="{}" disabled selected hidden>
                                    {{ $t("global.scoretype") }}
                                  </option>
                                  <option
                                    :value="sektionsfeld.notentyp"
                                    disabled
                                    selected
                                    hidden
                                  >
                                    {{
                                      sektionsfeld.notentyp
                                        ? sektionsfeld.notentyp.bezeichnung
                                        : $t("global.scoretype")
                                    }}
                                  </option>
                                  <option
                                    v-for="option in notentypen"
                                    :key="option.bezeichnung"
                                    :value="{
                                      id: option.id,
                                      bezeichnung: option.bezeichnung,
                                    }"
                                  >
                                    <span>
                                      {{ option.bezeichnung }}
                                    </span>
                                  </option>
                                </select>
                              </div>
                              <div class="d-flex flex-column flex-xl-row">
                                <!-- SEKTIONSFELD HINZUFÜGEN -->
                                <div
                                  class="d-flex align-items-center clickable hover-success mr-0 mr-xl-3 mb-1 mb-xl-0"
                                  @click="
                                    addSektionsfeld(index, sektionsfeldIndex)
                                  "
                                >
                                  <font-awesome-icon
                                    class="mr-2 fs-12"
                                    icon="fa-regular fa-circle-plus"
                                  />
                                  <span class="text-bold fs-10"
                                    >Sektionsfeld hinzufügen</span
                                  >
                                </div>

                                <!-- SEKTIONSFELD ENTFERNEN -->
                                <div
                                  class="d-flex align-items-center clickable hover-danger"
                                  @click="
                                    removeSektionsfeld(index, sektionsfeldIndex)
                                  "
                                >
                                  <font-awesome-icon
                                    class="mr-2 fs-12"
                                    icon="fa-regular fa-circle-minus"
                                  />
                                  <span class="text-bold fs-10"
                                    >Sektionsfeld entfernen</span
                                  >
                                </div>
                              </div>
                            </div>
                            <!--
                          <div
                            class="col-12 mr-2 d-flex align-items-center position-absolute"
                            style="bottom: 0; left: 0"
                          >
                            <hr
                              class="col"
                              style="
                                border-top: 1px solid #124862;
                                margin-left: 1.5rem;
                              "
                            />
                            <label class="col-5 p-0 m-0">
                              <input
                                class="text-center w-100"
                                type="text"
                                :placeholder="$t('global.sectionfield')"
                                v-model="sektionsfeld.bezeichnung"
                                style="
                                  color: #124862;
                                  background-color: #f5fbff;
                                  border: none;
                                  outline: none;
                                  height: 100% !important;
                                "
                              />
                              <font-awesome-icon
                                icon="fa-regular fa-pencil"
                                class="p-0 position-absolute clickable"
                                :style="{
                                  color: '#124862',
                                  top: '22%',
                                  right: '2%',
                                }"
                              />
                            </label>
                            <hr
                              class="col"
                              style="
                                border-top: 1px solid #124862;
                                margin-right: 1.5rem;
                              "
                            />
                          </div>
						  -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>

                <!-- SEKTIONEN FUNKTIONALITÄT (GEWICHTUNG, HINZUFÜGEN, ENTFERNEN)-->

                <div class="row px-3 my-2 d-flex">
                  <div class="d-flex align-items-center mr-auto">
                    <div class="mb-0 mr-2 ml-3">
                      <label class="text-bold mb-0 fs-10">{{
                        $t("global.weighting") + " " + $t("global.section")
                      }}</label>
                      <input
                        v-model.number="sektion.gewichtung"
                        class="form-control h-25-px w-50-px px-1 text-center"
                        placeholder="1"
                      />
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-xl-row">
                    <div
                      class="d-flex align-items-center ml-4 clickable hover-success mb-1 mb-xl-0"
                      @click="addSektion(index)"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-circle-plus"
                        class="mr-2 fs-14"
                      />
                      <span class="text-bold fs-10">Sektion hinzufügen</span>
                    </div>
                    <div
                      class="d-flex align-items-center ml-4 mr-3 clickable hover-danger"
                      @click="removeSektion(index)"
                    >
                      <font-awesome-icon
                        icon="fa-regular fa-circle-minus"
                        class="mr-2 fs-14"
                      />
                      <span class="text-bold fs-10">Sektion entfernen</span>
                    </div>
                  </div>
                </div>

                <!-- SEKTIONEN einklappen -->
                <div class="row d-flex pos-a right-40 bottom-19-negative">
                  <div
                    class="d-flex px-3 rounded-bottom justify-content-center align-items-center w-50-px bg-accent-blue hover-light-blue"
                    v-b-toggle="
                      'collapse-' +
                      'sektionsgruppe-' +
                      sektionsgruppenIndex +
                      '-sektion-' +
                      index
                    "
                    @click.prevent
                  >
                    <div class="when-open">
                      <font-awesome-icon
                        icon="fa-regular fa-angle-up"
                        size="lg"
                      />
                    </div>
                    <div class="when-closed">
                      <font-awesome-icon
                        icon="fa-regular fa-angle-down"
                        size="lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>

        <!-- SEKTIONEN HINZUFÜGEN/ENTFERNEN/AUSBLENDEN -->
        <div
          class="col-12 p-2 d-flex align-items-center rounded-bottom bg-medium-blue"
        >
          <div class="px-1 ml-3 d-flex flex-column mr-auto">
            <label class="mb-0 mr-2 text-bold fs-10">{{
              $t("global.totalweighting")
            }}</label>
            <input
              v-model.number="sektionsgruppe.gewichtung"
              class="form-control h-25-px w-50-px px-1 text-center mr-2"
              placeholder="1"
            />
          </div>
          <div
            class="d-flex flex-column align-items-start form-check p-0 mr-auto"
          >
            <label class="mb-1 mr-2 text-bold form-check-label fs-10">{{
              "Promotionsrelevant"
            }}</label>
            <input
              type="checkbox"
              v-model="sektionsgruppe.relevanzBerechnung"
              initial="true"
            />
          </div>
          <div class="d-flex flex-column flex-xl-row">
            <!-- SEKTIONSGRUPPE HINZUFÜGEN -->
            <div
              class="d-flex align-items-center clickable hover-success mr-0 mr-xl-3 mb-1 mb-xl-0"
              @click="addSektionsgruppe()"
            >
              <font-awesome-icon
                class="mr-2 fs-12"
                icon="fa-regular fa-circle-plus"
              />
              <span class="text-bold fs-10">Sektionsgruppe hinzufügen</span>
            </div>

            <!-- SEKTIONSGRUPPE ENTFERNEN -->
            <div
              class="d-flex align-items-center mr-3 clickable hover-danger"
              @click="removeSektionsgruppe()"
            >
              <font-awesome-icon
                class="mr-3 fs-12"
                icon="fa-regular fa-circle-minus"
              />
              <span class="text-bold fs-10">Sektionsgruppe entfernen</span>
            </div>
          </div>
        </div>

        <!--
        <div
          class="col-1 rounded-bottom d-flex justify-content-center mr-auto ml-2 p-1"
          style="background-color: #b5cfdc; height: 45px"
        >
          <div
            class="clickable d-flex align-items-center mr-2"
            @click="addSektion(index)"
          >
            <font-awesome-icon icon="fa-regular fa-plus" size="sm" />
          </div>
          <div
            class="clickable d-flex align-items-center"
            @click="removeSektion(index)"
          >
            <font-awesome-icon icon="fa-regular fa-minus" size="sm" />
          </div>
        </div>
		-->
        <div
          class="col-1 rounded-bottom d-flex justify-content-center ml-auto mr-2 py-1 px-2 bg-medium-blue h-25-px"
        >
          <!--  NOTENEINGABE & BERECHNUNG CHECKBOX - FUNKTION VORERST RAUSGENOMMEN, WIRD EVTL. IN ZUKUNFT WIEDER GEBRAUCHT
          <div class="d-flex justify-content-center align-items-center mx-2">
            <b-form-checkbox v-model="sektionsgruppe.relevanzNoteneingabe">
              <span> Noteneingabe </span>
            </b-form-checkbox>
          </div>

          <div class="d-flex justify-content-center align-items-center mx-2">
            <b-form-checkbox v-model="sektionsgruppe.relevanzBerechnung">
              <span> Berechnung </span>
            </b-form-checkbox>
          </div>
        -->

          <!--
          <div class="d-flex flex-wrap mx-2">
            <input
              v-model.number="sektionsgruppe.gesamtgewichtung"
              class="form-control mb-4"
              :placeholder="$t('global.totalweighting')"
            />
          </div>
		  -->
          <div
            class="clickable d-flex justify-content-center align-items-center mx-2 hover-light-blue"
            style="width: 40px"
            v-b-toggle="'collapse-' + 'sektionsgruppe-' + sektionsgruppenIndex"
            @click.prevent
          >
            <div class="when-open">
              <font-awesome-icon icon="fa-regular fa-angle-up" size="lg" />
            </div>
            <div class="when-closed">
              <font-awesome-icon icon="fa-regular fa-angle-down" size="lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VorlagePromotionsstrukturNotensektion",
  components: {},
  props: {
    styleDragElement: {
      type: Object,
    },
    styleDropzone: {
      type: Object,
    },
    sektionsgruppe: {
      type: Object,
      required: true,
    },
    sektionsgruppenIndex: {
      type: Number,
      required: true,
    },
    dragObject: {
      type: Object,
    },
    startDrag: {
      type: Function,
      required: true,
    },
    semesterAnzeige: {
      type: Object,
      required: true,
    },
    notentypen: {
      type: Array,
    },
    endnotenReiter: { type: Boolean, required: true },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onDrop(index, sektionsfeldIndex) {
      //Sektionsfeldbezeichnung setzen
      let sektionsfeldBezeichnung =
        this.sektionsgruppe.sektionen[index].sektionsfelder[sektionsfeldIndex]
          .bezeichnung;
      if (
        sektionsfeldBezeichnung == null ||
        sektionsfeldBezeichnung == "" ||
        sektionsfeldBezeichnung == "Sektionsfeld"
      ) {
        this.sektionsgruppe.sektionen[index].sektionsfelder[
          sektionsfeldIndex
        ].bezeichnung = this.dragObject.bezeichnung;
      }

      //dragObject anhand Typ ins entsprechende Array füllen
      if (this.dragObject.typ == "sektionsgruppe") {
        this.sektionsgruppe.sektionen[index].sektionsfelder[
          sektionsfeldIndex
        ].endnotensektionsgruppen.push(this.dragObject);
      } else if (this.dragObject.typ == "noteneingabe") {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotenNoteneingaben.push(this.dragObject);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].noteneingaben.push(this.dragObject);
        }
      } else {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.push(this.dragObject);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.push(this.dragObject);
        }
      }
      /*
      if (!this.dragObject.istNoteneingabe) {
        let bezeichnung =
          this.sektionsgruppe.sektionen[index].sektionsfelder[sektionsfeldIndex]
            .bezeichnung;
        if (this.endnotenReiter == false) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.push(this.dragObject);

          if (
            bezeichnung == null ||
            bezeichnung == "" ||
            bezeichnung == "Sektionsfeld"
          ) {
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].bezeichnung = this.dragObject.bezeichnung;
          }
        } else {
          if ("durchschnitt" in this.dragObject) {
            //this.dragObject.hasOwnProperty("durchschnitt")) {
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].endnotensektionsgruppen.push(this.dragObject);
            if (
              bezeichnung == null ||
              bezeichnung == "" ||
              bezeichnung == "Sektionsfeld"
            ) {
              this.sektionsgruppe.sektionen[index].sektionsfelder[
                sektionsfeldIndex
              ].bezeichnung = this.dragObject.bezeichnung;
            }
          } else {
            this.dragObject.istFach = true;
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].endnotensektionsgruppen.push(this.dragObject);
            if (
              bezeichnung == null ||
              bezeichnung == "" ||
              bezeichnung == "Sektionsfeld"
            ) {
              this.sektionsgruppe.sektionen[index].sektionsfelder[
                sektionsfeldIndex
              ].bezeichnung = this.dragObject.bezeichnung;
            }
          }
        }
      } else {
        let bezeichnung =
          this.sektionsgruppe.sektionen[index].sektionsfelder[sektionsfeldIndex]
            .bezeichnung;
        if (this.endnotenReiter == false) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].noteneingaben.push(this.dragObject);

          if (
            bezeichnung == null ||
            bezeichnung == "" ||
            bezeichnung == "Sektionsfeld"
          ) {
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].bezeichnung = this.dragObject.bezeichnung;
          }
        } else {
          //durchschnitt abfragen?
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotenNoteneingaben.push(this.dragObject);

          if (
            bezeichnung == null ||
            bezeichnung == "" ||
            bezeichnung == "Sektionsfeld"
          ) {
            this.sektionsgruppe.sektionen[index].sektionsfelder[
              sektionsfeldIndex
            ].bezeichnung = this.dragObject.bezeichnung;
          }
        }
      }*/
    },
    setDropzoneColor() {
      this.styleDropzone.borderStyle = "dashed";
      this.styleDropzone.borderColor = "#124862";
    },
    setDefaultDropzoneColor() {
      this.styleDropzone.borderColor = "#f5fbff";
    },
    deleteFach(index, sektionsfeldIndex, objektIndex, typ) {
      if (typ === "sektionsgruppe") {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotensektionsgruppen.splice(objektIndex, 1);
        }
      } else if (typ === "noteneingabe") {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotenNoteneingaben.splice(objektIndex, 1);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].noteneingaben.splice(objektIndex, 1);
        }
      } else {
        if (this.endnotenReiter == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.splice(objektIndex, 1);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.splice(objektIndex, 1);
        }
      }

      /*
      if (this.endnotenReiter == false) {
        if (istNoteneingabe == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].noteneingaben.splice(fachIndex, 1);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].faecher.splice(fachIndex, 1);
        }
      } else {
        if (istNoteneingabe == true) {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotenNoteneingaben.splice(fachIndex, 1);
        } else {
          this.sektionsgruppe.sektionen[index].sektionsfelder[
            sektionsfeldIndex
          ].endnotensektionsgruppen.splice(fachIndex, 1);
        }
      }*/
    },
    addSektion(index) {
      if (this.endnotenReiter == false) {
        const defaultSektion = {
          bezeichnung: "",
          sektionsfelder: [
            {
              bezeichnung: "",
              faecher: [],
              gewichtung: "",
              notentyp: { bezeichnung: "Notentyp" },
            },
          ],
        };

        this.sektionsgruppe.sektionen.splice(index + 1, 0, defaultSektion);
      } else {
        const defaultEndnotensektion = {
          bezeichnung: "",
          sektionsfelder: [
            {
              bezeichnung: "",
              faecher: [],
              endnotensektionsgruppen: [],
              gewichtung: "",
            },
          ],
        };

        this.sektionsgruppe.sektionen.splice(
          index + 1,
          0,
          defaultEndnotensektion
        );

        /*this.sektionsgruppe.sektionen.push({
          bezeichnung: "",
          sektionsfelder: [
            {
              bezeichnung: "",
              faecher: [],
              endnotensektionsgruppen: [],
              gewichtung: "",
            },
          ],
        });*/
      }
    },
    removeSektion(index) {
      this.sektionsgruppe.sektionen.splice(index, 1);
    },
    addSektionsfeld(index, sektionsfeldIndex) {
      if (this.endnotenReiter == false) {
        const defaultSektionsfeld = {
          bezeichnung: "",
          faecher: [],
          gewichtung: "",
          notentyp: { bezeichnung: "Notentyp" },
        };

        this.sektionsgruppe.sektionen[index].sektionsfelder.splice(
          sektionsfeldIndex + 1,
          0,
          defaultSektionsfeld
        );
      } else {
        const defaultEndnotensektionsfeld = {
          bezeichnung: "",
          faecher: [],
          endnotensektionsgruppen: [],
          gewichtung: "",
        };

        this.sektionsgruppe.sektionen[index].sektionsfelder.splice(
          sektionsfeldIndex + 1,
          0,
          defaultEndnotensektionsfeld
        );
      }
    },
    removeSektionsfeld(index, sektionsfeldIndex) {
      if (this.sektionsgruppe.sektionen[index].sektionsfelder.length > 1) {
        this.sektionsgruppe.sektionen[index].sektionsfelder.splice(
          sektionsfeldIndex,
          1
        );
      }
    },
    addSektionsgruppe() {
      this.$emit("addSektionsgruppe", this.sektionsgruppenIndex);
    },
    removeSektionsgruppe() {
      this.$emit("removeSektionsgruppe", this.sektionsgruppenIndex);
    },
  },
};
</script>

<style lang="scss">
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>


