import Api from "@/Api";
import KotlinAppApi from "@/KotlinAppApi";

import { debounce } from "@/utils/Debouncer";

import InfiniteLoading from "vue-infinite-loading";
import AppApi from "@/AppApi";

export default {
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      apiCall: null,
      //TemplateBildungsgangliste: null,
      filterId: null,
      anzahlDaten: null,
      anzahlDatenFilter: null,
      page: 0,
      anzahlProPage: 50,
      infiniteId: +new Date(),
      listData: [],
      filterAusgeloest: false,
      filters: {},
      filterValues: {},
      selectedIds: [],
      selectedObjects: [],
      lastAPICall: null, // Sicherheitscheck für Race Condition, ob der erste Call gemacht wurde
      selectAll: null,
      langtextLoopCount: 0,
      gesamtanzahl: null,
      listTab: 1,
      isBulkSelecting: false,
      allSelected: false,
    };
  },
  watch: {
    filterCountVals(val) {
      if (
        val[0] >= 1000 ||
        val[1] >= 1000 ||
        val[2] >= 1000 ||
        val[3] >= 1000
      ) {
        if (!document.getElementById("warning-label")) {
          const el = document.getElementById("number-of-records");
          let warningLabel = document.createElement("label");
          warningLabel.textContent = this.filterCountWarningMsg;
          warningLabel.classList.add("validation-warning-input");
          warningLabel.setAttribute("id", "warning-label");
          el.after(warningLabel);
        } else {
          document.getElementById("warning-label").textContent =
            this.filterCountWarningMsg;
        }
      } else if (document.getElementById("warning-label")) {
        let el = document.getElementById("warning-label");
        el.remove();
      }
    },
    selectedIds(val) {
      if (val.length === 0) {
        this.listTab = 1;
      }
    },
  },
  mounted() {
    this.$bus.$on("searchWithFilter", () => {
      this.filterSearch();
    });

    this.$bus.$on("resetFilter", () => {
      this.clearFilter();
    });
  },
  computed: {
    tableFieldsKeyList() {
      let keyList = this.$refs.selectableTable.fields.map((item) => item.key);
      keyList.push("id");
      return keyList;
    },
    filterCountWarningMsg() {
      return this.$t(
        "global.toomanyrecordsperformanceofthesystemwillbeaffected"
      );
    },
    filterCountVals() {
      return [
        this.filters.count,
        this.filterCount,
        this.filters.filterCount,
        this.filters.size,
      ];
    },
    listenfilter: {
      get() {
        return this.$store.state.filter.listenfilter;
      },
      set(val) {
        this.$store.state.filter.listenfilter = val;
      },
    },

    filterCountAndSpecials: function () {
      var pageCountSpecial = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.kategorieProp) {
        pageCountSpecial.veranstaltungstyp = this.kategorieProp;
      } else if (
        this.filterId == "faecher-liste" ||
        this.filterId == "template-faecher-liste"
      ) {
        pageCountSpecial.veranstaltungstyp = this.$CONST(
          "VERANSTALTUNGSTYPEN"
        ).MODULE;
      } else if (
        this.filterId == "klassen-liste" ||
        this.filterId == "template-klassen-liste"
      ) {
        pageCountSpecial.veranstaltungstyp = this.$CONST(
          "VERANSTALTUNGSTYPEN"
        ).STUDIES;
      } else if (this.filterId == "bildungs-liste-kurse") {
        pageCountSpecial.veranstaltungstyp = [
          "174D91487E8",
          "174D91487E0",
          "174D91487D6",
          "174D91487E4",
        ].join(",");
      } else if (this.filterId == "template-kurs-liste") {
        pageCountSpecial.veranstaltungstyp = "174D91487E8";
      }

      return pageCountSpecial;
    },
  },
  methods: {
    setHeaders(prefix, headerTexts) {
      if (this.langtextLoopCount < 15) {
        setTimeout(
          function () {
            if (this.$store.state.langtexts.loaded == true) {
              let count = 0;
              headerTexts.forEach((element) => {
                if (element != "") {
                  this.fields[count].label =
                    this.$store.state.langtexts.texts[this.$i18n.locale][
                      prefix
                    ][element];
                }
                count++;
              });
            } else {
              this.langtextLoopCount++;
              this.setHeaders(prefix, headerTexts);
            }
          }.bind(this),
          500
        );
      }
    },

    setBereiche(prefix, defaultValue, bereichTexte) {
      setTimeout(
        function () {
          if (this.$store.state.langtexts.loaded == true) {
            let count = 0;
            bereichTexte.forEach((element) => {
              if (element != "" && this.bereiche.length >= count + 1) {
                this.bereiche[count].bezeichnung =
                  this.$store.state.langtexts.texts[this.$i18n.locale][prefix][
                    element
                  ];
              }
              count++;
            });
            try {
              this.bereich.bezeichnung =
                this.$store.state.langtexts.texts[this.$i18n.locale][prefix][
                  defaultValue
                ];
            } catch (err) {
              console.log("Section is empty");
            }
            this.lastValidBereich =
              this.$store.state.langtexts.texts[this.$i18n.locale][prefix][
                defaultValue
              ];
          } else {
            this.setBereiche(prefix, defaultValue, bereichTexte);
          }
        }.bind(this),
        500
      );
    },

    setQueryParam(value) {
      return value !== null && value !== "" ? value : null;
    },

    setQueryParamList(value, valueKey = null) {
      if (valueKey !== null) {
        return value ? value.map((item) => item[valueKey]).join(",") : null;
      } else {
        return value ? value.join(",") : null;
      }
    },

    setFilterDate(data) {
      this.$set(this.filters, data.field, data.value);
      this.$set(this.filterValues, data.field, data.value);
    },

    initFilter(id, apiCall, defaultValues) {
      this.filterId = id;
      this.apiCall = apiCall;

      this.filters = this.listenfilter[id] ? this.listenfilter[id] : {};

      if (defaultValues) {
        for (const key in defaultValues) {
          this.filters[key] = defaultValues[key];
        }
      }
    },

    selectAllRows() {
      this.isBulkSelecting = true;
      this.allSelected = !this.allSelected;

      if (this.allSelected) {
        // Create a new Set of existing selected IDs for quick lookup
        const existingIds = new Set(this.selectedIds);

        // Update all items in the table and add to selectedObjects/selectedIds if not already included
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = true;
          if (!existingIds.has(item.id)) {
            this.selectedObjects.push(item);
            existingIds.add(item.id); // Add directly to Set
          }
        });

        // Update selectedIds directly from the Set
        this.selectedIds = Array.from(existingIds);
      } else {
        const deselectedIds = new Set();

        // Deselect all current items in the table without altering pre-existing selections not in the current list
        this.$refs.selectableTable.items.forEach((item) => {
          item.selected = false;
          deselectedIds.add(item.id);
        });

        this.selectedObjects = this.selectedObjects.filter(
          (obj) => !deselectedIds.has(obj.id)
        );
        this.selectedIds = this.selectedIds.filter(
          (id) => !deselectedIds.has(id)
        );
      }

      // Update count
      this.anzahlDaten = this.selectedIds.length;
      this.isBulkSelecting = false;
    },

    onRowSelected(row) {
      if (this.isBulkSelecting) return;

      if (this.selectAll) {
        let timeDiff = Date.now() - this.selectAll;
        if (timeDiff < 100) return;
      }

      this.$set(row.item, "selected", !row.item.selected);

      if (row.item.selected) {
        if (!this.selectedIds.includes(row.item.id)) {
          this.selectedObjects.push(row.item);
          this.selectedIds.push(row.item.id);
        }
      } else {
        this.selectedObjects = this.selectedObjects.filter(
          (item) => item.id !== row.item.id
        );
        this.selectedIds = this.selectedIds.filter((id) => id !== row.item.id);
      }

      this.anzahlDaten = this.selectedIds.length;
    },

    clearSelectedRows() {
      if (this.$refs.headerCheckbox) {
        const hasUncheckedEntries =
          this.$refs.selectableTable.items.length > 0 &&
          this.$refs.selectableTable.items.some((item) => !item.selected);

        if (hasUncheckedEntries) {
          this.$refs.headerCheckbox.localChecked = false;
          this.allSelected = false;
        } else {
          this.$refs.headerCheckbox.localChecked = true;
          this.allSelected = true;
        }
      }
    },

    // Function to filter only the properties needed for display in the list + the id property.
    // returns the updated array of objects
    filterProperties(props, arrayOfObjects) {
      return arrayOfObjects.map((obj) => {
        return props.reduce((acc, prop) => {
          if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            acc[prop] = obj[prop];
          }
          return acc;
        }, {});
      });
    },

    resetLoading: debounce((self) => {
      self.page = 0;
      self.listData = [];
      // self.selectedIds = [];
      // self.anzahlDaten = 0;
      // self.selectedObjects = [];
      self.infiniteId += 1;
    }, 500),

    initializeSelection() {
      if (this.selectedIds.length === 0) return;

      // To correctly update the checkboxes we need to have in selectedObjects the same object references as in the current listData of the b-table
      // Create a Map for quick lookup of selected objects by their id
      const selectedMap = new Map(
        this.selectedObjects.map((selectedObject) => [
          selectedObject.id,
          selectedObject,
        ])
      );

      // Iterate over selectableTable items once
      this.$refs.selectableTable.items.forEach((item) => {
        // Check if the item exists in the selectedMap
        if (selectedMap.has(item.id)) {
          // Update the object in selectedObjects with the new item
          const index = this.selectedObjects.findIndex(
            (obj) => obj.id === item.id
          );
          this.selectedObjects.splice(index, 1, item);

          // Update item selected property
          item.selected = true;
        } else {
          item.selected = false;
        }
      });

      this.clearSelectedRows();
      // optional: zwischengespeicherte Ids aus Browswer Session Speicher holen
    },

    infiniteHandler($state, api = "app") {
      if (this.apiCall) {
        this.loading = true;
        let filter = { ...this.filterCountAndSpecials, ...this.filterValues };
        this.lastAPICall = JSON.stringify(filter);

        switch (api) {
          case "app": {
            Api.get(this.apiCall, {
              params: filter,
            }).then((response) => {
              if (response.data.length === this.anzahlProPage) {
                this.page += 1;
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.loaded();
              } else {
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.complete();
              }
              this.initializeSelection();
              this.loading = false;
            });
            break;
          }
          case "kotlin-app-api": {
            KotlinAppApi.get(this.apiCall, {
              params: filter,
            }).then((response) => {
              if (response.data.length === this.anzahlProPage) {
                this.page += 1;
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.loaded();
              } else {
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.complete();
              }
              this.initializeSelection();
              this.loading = false;
            });
            break;
          }
          case "app-api": {
            AppApi.get(this.apiCall, {
              params: filter,
            }).then((response) => {
              if (response.data.length === this.anzahlProPage) {
                this.page += 1;
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.loaded();
              } else {
                this.listData.push(...response.data);
                this.anzahlDatenFilter = this.listData.length;
                this.gesamtanzahl = response.data[0]?.gesamtanzahl
                  ? response.data[0].gesamtanzahl
                  : 0;
                $state.complete();
              }
              this.initializeSelection();
              this.loading = false;
            });
            break;
          }
        }
      } else {
        console.log("API Call nicht gesetzt");
      }
    },

    filterSearch() {
      if (this.filterId) {
        this.filterAusgeloest = true;
        this.filterValues = this.queryParams;
        // this.clearSelectedRows();
        this.listenfilter[this.filterId] = this.filters;
        if (this.lastAPICall) this.resetLoading(this);
      } else {
        console.log("Keine Filter ID gesetzt");
      }
    },

    clearFilter() {
      this.filters = [];
      this.filterValues = {};
      if (this.filterAusgeloest) {
        this.filterAusgeloest = false;
        if (this.lastAPICall) this.resetLoading(this);
      }
    },
  },
};
