<template>
  <div class="tile-container">
    <div id="aktivitaet-bearbeiten-modal" class="modal fade">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <ValidationObserver v-slot="{ invalid }">
            <div class="modal-header">
              <modal-close-button-new @confirmed="closeModal" />
              <h4 class="modal-title">
                {{
                  aktivitaet.id
                    ? $t("global.editactivity")
                    : $t("global.addactivity")
                }}
              </h4>
            </div>
            <div class="modal-body table-wrapper">
              <div v-if="kontaktbasiert === null">
                <div class="txtTrsf">
                  {{ $t("global.whatkindofpendingissue") }}
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <button class="btn btnTrsf" @click="unsetKontaktbasiert">
                        {{ $t("global.personalpending") }}
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <button class="btn btnTrsf" @click="setKontaktbasiert">
                        {{ $t("global.contactbasedpending") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="kontaktbasiert !== null">
                <div class="row">
                  <div class="col-xl-6">
                    <button class="btn btnBack" @click="unsetAuswahl">
                      <font-awesome-icon
                        icon="fa-solid fa-arrow-left"
                        size="lg"
                      />
                      <span> {{ $t("global.changekindofpending") }}</span>
                    </button>
                  </div>
                  <div class="col-xl-6">
                    <single-select-person
                      id="aktivitaet-person"
                      :person="kontaktPerson"
                      :firma="kontaktFirma"
                      :label="$t('global.contactperson') + '*'"
                      :required="true"
                      editable
                      :allowOpen="false"
                      @confirmed="setPerson"
                      :tabindex="1"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <input
                          v-model.trim="aktivitaet.bezeichnung"
                          v-bind:class="{ 'border-danger': !passed }"
                          class="form-control"
                        />
                        <label v-bind:class="{ 'text-danger': !passed }">
                          {{ $t("global.pendingname") }}*
                        </label>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-3">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          type="date"
                          class="form-control"
                          v-model="geplantdatum"
                          @change="checkAktDatum()"
                          :class="pastDate ? 'text-danger' : ''"
                          v-tooltip.hover="
                            pastDate
                              ? (text = $t('global.planneddatebeforetoday'))
                              : (text = '')
                          "
                        />
                      </div>
                      <label>{{ $t("global.planneddate") }}</label>
                    </div>
                  </div>
                  <div v-if="geplantdatum" class="col-xl-3">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          type="time"
                          class="form-control"
                          v-model="aktivitaet.geplantzeit"
                        />
                        <div
                          class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-clock"
                            class="m-auto"
                          />
                        </div>
                      </div>
                      <label>{{ $t("global.plannedtime") }}</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          type="date"
                          class="form-control"
                          v-model="effektivdatum"
                          @change="checkGeplantdatum()"
                          :class="pastPlannedDate ? 'text-danger' : ''"
                          v-tooltip.hover="
                            pastPlannedDate
                              ? (text = $t('global.effectivedatebeforeplanned'))
                              : (text = '')
                          "
                        />
                      </div>
                      <label>{{ $t("global.effectivedate") }}</label>
                    </div>
                  </div>
                  <div v-if="effektivdatum" class="col-xl-3">
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          type="time"
                          class="form-control"
                          v-model="aktivitaet.effektivzeit"
                        />
                        <div
                          class="input-group-append text-medium-light h-100 mr-2 d-flex align-items-center"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-clock"
                            class="m-auto"
                          />
                        </div>
                      </div>
                      <label>{{ $t("global.effectivetime") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <v-select
                          v-model="aktivitaet.typ"
                          v-bind:class="{ 'border-danger': !passed }"
                          label="bezeichnung"
                          :options="typen"
                          :tabindex="6"
                          :placeholder="$t('global.type')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">
                          {{ $t("global.type") }}*</label
                        >
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <ValidationProvider
                        rules="required"
                        immediate
                        v-slot="{ passed }"
                      >
                        <v-select
                          v-model="aktivitaet.status"
                          v-bind:class="{ 'border-danger': !passed }"
                          label="bezeichnung"
                          :options="stati"
                          :tabindex="7"
                          :placeholder="$t('global.status')"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label v-bind:class="{ 'text-danger': !passed }">
                          {{ $t("global.status") }}*</label
                        >
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <textarea
                        v-model.trim="aktivitaet.beschreibung"
                        type="text"
                        class="form-control"
                        placeholder=" "
                        rows="5"
                      />
                      <label>{{ $t("global.description") }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="kontaktbasiert" class="row">
                  <div class="col-xl-4">
                    <single-select-firma
                      id="aktivitaet-firma"
                      :firma="kontaktFirma"
                      :editable="true"
                      :allowOpen="true"
                      :label="$t('global.involvedcompany')"
                      @confirmed="setFirma"
                    />
                  </div>
                  <div class="col-xl-8">
                    <multi-select-person
                      id="aktivitaet-verantwortlicher"
                      :personen="verantwortlicher"
                      :label="$t('global.involvedparticipants')"
                      editable
                      :open="false"
                      @confirmed="setVerantwortlicher"
                      :tabindex="8"
                    />
                  </div>
                </div>
                <div v-if="aktivitaet.id && shown" class="row">
                  <div class="col-xl-12 p-0">
                    <div class="tab-container p-3">
                      <dokumentenliste
                        :aktivitaet="aktivitaet.id"
                        :dokumentEditable="shown"
                        :shown="shown"
                        :key="componentKey"
                        @updateComponent="updateDokumentenliste"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="kontaktbasiert !== null"
                type="button"
                class="btn btn-danger"
                :disabled="pendenzenIsBusy"
                @click="loeschen"
              >
                {{ $t("global.delete") }}
              </button>
              <button type="button" class="btn btn-default" @click="closeModal">
                {{ $t("global.close") }}
              </button>
              <button
                v-if="kontaktbasiert !== null"
                type="button"
                :disabled="invalid || pendenzenIsBusy"
                class="btn btn-primary"
                @click="uebernehmen"
              >
                {{ $t("global.apply") }}
              </button>
            </div>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCloseButtonNew from "@/components/global/ModalCloseButtonNew";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import MultiSelectPerson from "@/components/global/MultiSelectPerson.vue";
import SingleSelectFirma from "@/components/global/SingleSelectFirma.vue";
import Dokumentenliste from "@/components/Reiter/Dokumentenliste";

import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import { LADE_AUSWAHLWERTE_CRM } from "@/store/crm/actions.type";

export default {
  name: "AktivitaetModal",
  components: {
    ModalCloseButtonNew,
    SingleSelectPerson,
    SingleSelectFirma,
    MultiSelectPerson,
    Dokumentenliste,
  },
  mixins: [page, ChangeWatcher],
  props: {
    pendenzenIsBusy: {
      type: Boolean,
      default: false,
    },
    isKontaktbasiert: {
      type: Boolean,
      default: false,
    },
    kontakt: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      kontaktPerson: {},
      kontaktFirma: {},
      verantwortlicher: [{}],
      aktivitaet: {
        person: {},
        verantwortlicher: [{}],
        firma: {},
        typ: {},
        status: {},
      },
      verantwortliche: [],
      neueAktivitaet: false,
      shown: false,
      kontaktbasiert: null,
      geplantdatum: null,
      effektivdatum: null,
      pastDate: false,
      pastPlannedDate: false,
      componentKey: 0,
      isDozent: false,
    };
  },
  computed: {
    typen() {
      if (this.$store) return this.$store.state.crm.aktivitaettypen;
      return [];
    },
    stati() {
      if (this.$store) return this.$store.state.crm.aktivitaetstati;
      return [];
    },
  },
  watch: {},
  created() {
    if (
      this.gruppen.find(
        (gruppe) =>
          gruppe.id == this.$CONST("GRUPPEN").INSTRUCTOR ||
          gruppe.id == "1774DD1D796" // Dozentengruppe
      )
    )
      this.isDozent = true;
  },
  mounted() {
    if (this.typen.length == 0)
      this.$store.dispatch(`crm/${LADE_AUSWAHLWERTE_CRM}`);

    this.$bus.$on("aktivitaet-modal-show", (data) => {
      if (data.neu) {
        if (this.isKontaktbasiert) {
          this.kontaktbasiert = true;
        } else {
          this.kontaktbasiert = null;
        }

        if (this.kontakt) {
          this.verantwortlicher = [this.kontakt];
        }

        data.status = this.stati.find(
          (status) => status.id == this.$CONST("CONFIG").DEFAULTACTIVITYSTATUS
        );

        data.typ = this.typen.find(
          (typ) => typ.id == this.$CONST("CONFIG").DEFAULTACTIVITYTYPE
        );
      } else {
        this.kontaktbasiert = this.aktivitaet.kontaktbasiert;
        this.verantwortlicher = this.aktivitaet.verantwortlicher;
      }

      this.aktivitaet = Object.assign({}, data);
      this.kontaktPerson = data.neu
        ? this.angemeldeterMitarbeiter.person
        : this.aktivitaet.person;
      this.kontaktFirma = this.aktivitaet.firma;

      this.neueAktivitaet = data.neu ? true : false;

      this.geplantdatum = data.neu
        ? null
        : this.dateFormatZuYMD(this.aktivitaet.geplantdatum);
      this.effektivdatum = data.neu
        ? null
        : this.dateFormatZuYMD(this.aktivitaet.effektivdatum);

      if (!this.shown) this.openModal();
    });
  },
  methods: {
    // Funktion zum Umwandeln von "YYYY-MM-DD" in "DD.MM.YYYY"
    dateFormatZuDMY(dateStr) {
      if (!dateStr) return null;
      const [year, month, day] = dateStr.split("-");
      return `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}`;
    },
    // Funktion zum Umwandeln von "DD.MM.YYYY" in "YYYY-MM-DD"
    dateFormatZuYMD(dateStr) {
      if (!dateStr) return null;
      const [day, month, year] = dateStr.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    setPerson(person) {
      this.kontaktPerson = person;
    },

    setFirma(firma) {
      this.kontaktFirma = firma;
    },

    setVerantwortlicher(person) {
      this.verantwortlicher = person;
    },

    openModal() {
      this.shown = true;

      $("#aktivitaet-bearbeiten-modal").modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.geplantdatum = null;
      this.effektivdatum = null;
      this.pastDate = false;
      this.pastPlannedDate = false;
      this.shown = false;
      $("#aktivitaet-bearbeiten-modal").modal("hide");
    },

    loeschen() {
      this.$emit("aktivitaetDeleted", this.aktivitaet);
      this.geplantdatum = null;
      this.effektivdatum = null;
      this.shown = false;
      $("#aktivitaet-bearbeiten-modal").modal("hide");
    },
    uebernehmen() {
      this.aktivitaet.person = this.kontaktPerson;
      this.aktivitaet.firma = this.kontaktFirma;
      this.aktivitaet.verantwortlicher = this.verantwortlicher;
      this.aktivitaet.kontaktbasiert = this.kontaktbasiert;
      this.aktivitaet.geplantdatum = this.dateFormatZuDMY(this.geplantdatum);
      this.aktivitaet.effektivdatum = this.dateFormatZuDMY(this.effektivdatum);

      if (this.neueAktivitaet) {
        this.$emit("aktivitaetCreated", this.aktivitaet);
      } else {
        this.$emit("aktivitaetChanged", this.aktivitaet);
        this.closeModal();
      }
    },
    unsetKontaktbasiert() {
      this.kontaktbasiert = false;
      this.kontaktFirma = {};
      this.verantwortlicher = undefined;
    },
    setKontaktbasiert() {
      this.kontaktbasiert = true;
    },
    unsetAuswahl() {
      this.kontaktbasiert = null;
    },
    //prüft ob das Geplantdatum vor dem heutigen ist
    checkAktDatum() {
      if (!this.geplantdatum) return (this.pastDate = false);

      const today = new Date().toISOString().slice(0, 10);
      if (this.geplantdatum < today) {
        this.pastDate = true;
      } else {
        this.pastDate = false;
      }
    },
    //prüft ob das Effektivdatum vor dem geplantem ist
    checkGeplantdatum() {
      if (!this.effektivdatum) return (this.pastPlannedDate = false);

      if (this.effektivdatum < this.geplantdatum) {
        this.pastPlannedDate = true;
      } else {
        this.pastPlannedDate = false;
      }
    },
    updateDokumentenliste() {
      this.componentKey++;
    },
  },
};
</script>
<style scoped>
/* Scoped Style, da die Paddings der .col-* ansonsten von den Tiles der Startseite ueberschrieben werden */
[class^="col-"],
[class*="col-"] {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
</style>
